import React, { useState, useEffect } from "react"
import { withRouter } from "react-router-dom"
import { IMAGE } from "@/assets"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { MTabbar } from "@/component"
import { Modal, Button, Toast } from 'antd-mobile'
import { post } from "@/service"
import "./styles.less"

const supportEmail = "renrenpaymoney"

const IndexPage = ({ history }) => {
  const [emailVisible, setEmailVisible] = useState(false)
  const [logoutVisible, setLogoutVisible] = useState(false)
  const [data, setData] = useState(null)

  useEffect(() => {
    async function getData() {
      try {
        Toast.show({
          icon: 'loading',
          content: '加载中...',
          duration: 0
        })
        const resp = await post("/app/account/info")
        Toast.clear()
        setData(resp)
      } catch (error) {
        Toast.show({
          icon: 'fail',
          content: JSON.stringify(error)
        })
      }
    }
    getData()
  }, [])
  
  return (
    <div className="mine-page">
      <p className="title">我的</p>
      <div className="sessin-div">
        <p className="key">邮箱</p>
        <p className="value">{ data?.email }</p>
      </div>
      <div className="sessin-div" onClick={() => history.push("/changePassword")}>
        <p className="key">修改密码</p>
        <img src={IMAGE.clickTag} alt="" className="tag" />
      </div>
      <div className="sessin-div" onClick={() => setEmailVisible(true)}>
        <p className="key">联系我们</p>
        <img src={IMAGE.clickTag} alt="" className="tag" />
      </div>
      <Button className="logout-button" onClick={() => setLogoutVisible(true)}>退出登录</Button>
      <Modal visible={emailVisible} bodyClassName="mine-modal" content={
        <div className="modal-content-div">
          <p className="title">联系我们</p>
          <p className="content">{`微信号：${supportEmail}`}</p>
          <div className="buttons-div">
            <Button className="button cancle" onClick={() => setEmailVisible(false)}>取消</Button>
            <CopyToClipboard text={supportEmail} onCopy={() => {
              setEmailVisible(false)
              Toast.show({
                icon: "success",
                content: "复制成功",
              })
            }}>
              <Button className="button confirm">复制微信号</Button>
            </CopyToClipboard>
          </div>
        </div>
      } />
      <Modal visible={logoutVisible} bodyClassName="mine-modal" content={
        <div className="modal-content-div">
          <p className="title">退出登录</p>
          <p className="content">确定退出登录吗</p>
          <div className="buttons-div">
            <Button className="button cancle" onClick={() => setLogoutVisible(false)}>取消</Button>
            <Button className="button confirm" onClick={() => {
              localStorage.removeItem("token")
              setLogoutVisible(false)
              history.push("/join")
            }}>确定</Button>
          </div>
        </div>
      } />
      <MTabbar selIndex={3} />
    </div>
  )
}
export default withRouter(IndexPage)