import React from "react"
import { Modal, Button, Toast } from "antd-mobile"
import { CopyToClipboard } from "react-copy-to-clipboard"
import "./styles.less"

const SUPPORTEMAIL = "renrenpaymoney"
const IndexComponent = ({
  visible,
  setVisible
}) => {

  return (
    <Modal 
      visible={visible}
      bodyClassName="m_contact" 
      content={
        <div className="m_contact_content">
          <p className="m_contact_title">联系我们</p>
          <p className="m_contact_desc">{`微信号：${SUPPORTEMAIL}`}</p>
          <div className="m_contact_buttons">
            <Button className="m_contact_button" onClick={() => setVisible(false)}>取消</Button>
            <CopyToClipboard text={SUPPORTEMAIL} onCopy={() => {
              setVisible(false)
              Toast.show({
                icon: "success",
                content: "复制成功",
              })
            }}>
              <Button className="m_contact_button" style={{ "---color": "#1437FA" }}>复制微信号</Button>
            </CopyToClipboard>
          </div>
        </div>
      } 
    />
  )
}

export default IndexComponent